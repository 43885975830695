import { Controller } from '@hotwired/stimulus'

import { exportEncryptionKey, generateEncryptionKey } from '@samedi/crypto-js/crypto'

import {
  PatientPublicKey,
  getMasterKeys,
  loadDeviceKeyFromSessionOnce,
} from '../encryption/encryption'
import {
  generateDeviceKeyPair,
  importPatientUserKeyPairPublicKey,
  exportDeviceKeyPrivateKey,
  exportDeviceKeyPublicKey,
  exportPatientUserMasterKey,
} from '../encryption/keyHandling'

export default class extends Controller {
  static values = { proxyPublicKeyJwk: String }
  static targets = [
    'devicePublicKeyJwk',
    'devicePrivateKeyEncrypted',
    'sessionKeyEncrypted',
    'patientMasterKeys',
    'submitButton',
  ]

  declare private readonly proxyPublicKeyJwkValue: string
  declare private devicePublicKeyJwkTarget: HTMLInputElement
  declare private devicePrivateKeyEncryptedTarget: HTMLInputElement
  declare private sessionKeyEncryptedTarget: HTMLInputElement
  declare private patientMasterKeysTarget: HTMLDivElement
  declare private submitButtonTarget: HTMLButtonElement

  async connect() {
    await loadDeviceKeyFromSessionOnce()
    await this.fillInEncryptionData()
    this.enableSubmitButton()
  }

  private async fillInEncryptionData() {
    const { devicePublicKeyJwk, devicePrivateKeyEncrypted, sessionKeyEncrypted, masterKeys } =
      await this.generateEncryptionData()

    this.devicePublicKeyJwkTarget.value = devicePublicKeyJwk as string
    this.devicePrivateKeyEncryptedTarget.value = devicePrivateKeyEncrypted
    this.sessionKeyEncryptedTarget.value = sessionKeyEncrypted
    masterKeys.forEach((masterKey: { id: string; keyDataEncrypted: string }, index: number) => {
      const { keyIdInput, keyDataInput } = this.createMasterKeyInputs(masterKey, index)
      this.patientMasterKeysTarget.appendChild(keyIdInput)
      this.patientMasterKeysTarget.appendChild(keyDataInput)
    })
  }

  private createMasterKeyInputs(
    masterKey: { id: string; keyDataEncrypted: string },
    index: number,
  ) {
    return {
      keyIdInput: Object.assign(document.createElement('input'), {
        type: 'hidden',
        name: `proxy_entitlement[patient_user_master_key_data_attributes][${index}][id]`,
        value: masterKey.id,
      }),
      keyDataInput: Object.assign(document.createElement('input'), {
        type: 'hidden',
        name: `proxy_entitlement[patient_user_master_key_data_attributes][${index}][data_encrypted]`,
        value: masterKey.keyDataEncrypted,
      }),
    }
  }

  private async generateEncryptionData() {
    const masterKeys = await getMasterKeys()
    const proxyPublicKey = await importPatientUserKeyPairPublicKey(
      JSON.parse(this.proxyPublicKeyJwkValue),
    )
    const deviceKeyPair = await generateDeviceKeyPair()
    const sessionKey = await generateEncryptionKey(['wrapKey', 'unwrapKey'])

    return {
      devicePublicKeyJwk: JSON.stringify(await exportDeviceKeyPublicKey(deviceKeyPair.publicKey)),
      devicePrivateKeyEncrypted: await exportDeviceKeyPrivateKey(
        sessionKey,
        deviceKeyPair.privateKey,
      ),
      sessionKeyEncrypted: await exportEncryptionKey(sessionKey, proxyPublicKey),
      masterKeys: await Promise.all(
        masterKeys.map(async (key: PatientPublicKey) => ({
          id: key.id,
          keyDataEncrypted: await exportPatientUserMasterKey(deviceKeyPair.publicKey, key.key),
        })),
      ),
    }
  }

  private enableSubmitButton() {
    this.submitButtonTarget.removeAttribute('disabled')
  }
}
