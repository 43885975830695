import { Controller } from '@hotwired/stimulus'

export default class ButtonStatusController extends Controller {
  static targets = ['inputField', 'button']

  connect() {
    this.buttonTarget.disabled = true
  }

  toggleButtonStatus() {
    const hasInput = this.inputFieldTargets.every((e: HTMLInputElement) => e.value.length > 0)

    this.buttonTarget.disabled = !hasInput
  }

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly inputFieldTargets: HTMLInputElement[]
}
