import { Controller } from '@hotwired/stimulus'

import { CSRFToken } from '../encryption/encryption'

export default class extends Controller {
  static targets = ['generateTokenStep', 'copyTokenStep', 'tokenTextField']

  declare private generateTokenStepTarget: HTMLElement
  declare private copyTokenStepTarget: HTMLElement
  declare private tokenTextFieldTarget: HTMLInputElement

  async connect() {}

  async generateToken() {
    this.switchStep()
    const token = await this.requestGenerateToken()
    this.tokenTextFieldTarget.value = token
  }

  async copyToken() {
    navigator.clipboard.writeText(this.tokenTextFieldTarget.value)
  }

  private switchStep() {
    this.generateTokenStepTarget.classList.add('hidden')
    this.copyTokenStepTarget.classList.remove('hidden')
  }

  private async requestGenerateToken(): Promise<string> {
    const submitRequest = await fetch('/proxy_tokens', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': CSRFToken(),
      },
    })

    const response = await submitRequest.json()

    return response.token
  }
}
