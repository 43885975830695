/* Based on https://goessner.net/download/prj/jsonxml/xml2json.js

  This work is licensed under Creative Commons GNU LGPL License.

	License: http://creativecommons.org/licenses/LGPL/2.1/
   Version: 0.9
	Author:  Stefan Goessner/2006
	Web:     http://goessner.net/ 
*/

import { type JSONObject, type JSONValue } from '../../../types/jsonObject'

export function xml2json(xml: Document | Node, tab = '  '): JSONObject {
  const X = {
    toObj: function (xml: Element): JSONObject | string | null {
      let o: JSONObject | string | null = {}
      if (xml.nodeType === 1) {
        // element node ..
        if (xml.attributes.length)
          // element with attributes  ..
          for (let i = 0; i < xml.attributes.length; i++)
            o['@' + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || '').toString()
        if (xml.firstChild) {
          // element has child nodes ..
          let textChild = 0
          let cdataChild = 0
          let hasElementChild = false
          for (let n: ChildNode | null = xml.firstChild; n; n = n.nextSibling) {
            if (n.nodeType === 1) hasElementChild = true
            else if (n.nodeType === 3 && n.nodeValue?.match(/[^ \f\n\r\t\v]/))
              textChild++ // non-whitespace text
            else if (n.nodeType === 4) cdataChild++ // cdata section node
          }
          if (hasElementChild) {
            if (textChild < 2 && cdataChild < 2) {
              // structured element with evtl. a single text or/and cdata node ..
              X.removeWhite(xml)
              for (let n: ChildNode | null = xml.firstChild; n; n = n.nextSibling) {
                if (n.nodeType === 3)
                  // text node
                  o['#text'] = X.escape(n.nodeValue)
                else if (n.nodeType === 4)
                  // cdata node
                  o['#cdata'] = X.escape(n.nodeValue)
                else if (o[n.nodeName]) {
                  // multiple occurence of element ..
                  if (o[n.nodeName] instanceof Array)
                    (o[n.nodeName] as Array<JSONValue>)[
                      (o[n.nodeName] as Array<JSONValue>).length
                    ] = X.toObj(n as Element)
                  else o[n.nodeName] = [o[n.nodeName], X.toObj(n as Element)]
                } // first occurence of element..
                else o[n.nodeName] = X.toObj(n as Element)
              }
            } else {
              // mixed content
              if (!xml.attributes.length) o = X.escape(X.innerXml(xml))
              else o['#text'] = X.escape(X.innerXml(xml))
            }
          } else if (textChild) {
            // pure text
            if (!xml.attributes.length) o = X.escape(X.innerXml(xml))
            else o['#text'] = X.escape(X.innerXml(xml))
          } else if (cdataChild) {
            // cdata
            if (cdataChild > 1) o = X.escape(X.innerXml(xml))
            else
              for (let n: ChildNode | null = xml.firstChild; n; n = n.nextSibling)
                o['#cdata'] = X.escape(n.nodeValue)
          }
        }
        if (!xml.attributes.length && !xml.firstChild) o = null
      } else if (xml.nodeType === 9) {
        // document.node
        o = X.toObj((xml as unknown as Document).documentElement)
      } else alert('unhandled node type: ' + xml.nodeType) // eslint-disable-line no-alert
      return o
    },
    toJson: function (o: JSONValue, name: string, ind: string) {
      let json = name ? '"' + name + '"' : ''
      if (o instanceof Array) {
        for (let i = 0, n = o.length; i < n; i++) o[i] = X.toJson(o[i], '', ind + '\t')
        json +=
          (name ? ':[' : '[') +
          (o.length > 1
            ? '\n' + ind + '\t' + o.join(',\n' + ind + '\t') + '\n' + ind
            : o.join('')) +
          ']'
      } else if (o == null) json += (name && ':') + 'null'
      else if (typeof o === 'object') {
        const arr = []
        for (const m in o) arr[arr.length] = X.toJson(o[m], m, ind + '\t')
        json +=
          (name ? ':{' : '{') +
          (arr.length > 1
            ? '\n' + ind + '\t' + arr.join(',\n' + ind + '\t') + '\n' + ind
            : arr.join('')) +
          '}'
      } else if (typeof o === 'string') json += (name && ':') + '"' + o.toString() + '"'
      else json += (name && ':') + o.toString()
      return json
    },
    innerXml: function (node: Element) {
      let s = ''
      if ('innerHTML' in node) s = node.innerHTML
      else {
        const asXml = function (n: Element) {
          let s = ''
          if (n.nodeType === 1) {
            s += '<' + n.nodeName
            for (let i = 0; i < n.attributes.length; i++)
              s +=
                ' ' +
                n.attributes[i].nodeName +
                '="' +
                (n.attributes[i].nodeValue || '').toString() +
                '"'
            if (n.firstChild) {
              s += '>'
              for (let c: ChildNode | null = n.firstChild; c; c = c.nextSibling)
                s += asXml(c as Element)
              s += '</' + n.nodeName + '>'
            } else s += '/>'
          } else if (n.nodeType === 3) s += n.nodeValue
          else if (n.nodeType === 4) s += '<![CDATA[' + n.nodeValue + ']]>'
          return s
        }
        for (let c: ChildNode | null = (node as Element).firstChild; c; c = c.nextSibling)
          s += asXml(c as Element)
      }
      return s
    },
    escape: function (txt: string | null): string {
      if (!txt) return ''
      return txt
        .replace(/[\\]/g, '\\\\')
        .replace(/[\"]/g, '\\"') // eslint-disable-line no-useless-escape
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
    },
    removeWhite: function (e: Element): Element {
      e.normalize()
      for (let n = e.firstChild; n; ) {
        if (n.nodeType === 3) {
          // text node
          if (!n.nodeValue?.match(/[^ \f\n\r\t\v]/)) {
            // pure whitespace text node
            const nxt = n.nextSibling
            e.removeChild(n)
            n = nxt
          } else n = n.nextSibling
        } else if (n.nodeType === 1) {
          // element node
          X.removeWhite(n as Element)
          n = n.nextSibling
        } // any other node
        else n = n.nextSibling
      }
      return e
    },
  }

  const root: Element =
    xml.nodeType === 9 ? ((xml as Document).documentElement as Element) : (xml as Element)

  if (root.nodeName === 'parsererror') throw new Error(X.innerXml(root))

  return { [root.nodeName]: X.toObj(root) as JSONObject }
}
