// Needed for Edge (Polyfill)
import { TextDecoder } from 'text-encoding'

// This function turns a reader to a ReadableStream. This is needed because some browsers
// can not return a feature-complete ReadableStream, even though a Polyfill is in use.
// We need to explicitily transform e.g. the native Firefox Readable Stream (coming through new Response(file).body)
// to a complete ReadableStream. It then supports pipe and pipeTo. This can be removed when the Polyfill is smarter.
interface Options {
  decode?: boolean
  upload?: boolean
}

function readerToStream(reader: ReadableStreamReader<Uint8Array>, options?: Options) {
  const decoder = new TextDecoder('utf-8')
  let closed = false
  const stream = new ReadableStream(
    {
      start(controller) {
        async function push(): Promise<void> {
          // @ts-ignore
          const { done, value } = await reader.read()
          if (closed) {
            await controller.close()
            return
          }

          if (value) {
            let decodedValue = (options || {}).decode ? decoder.decode(value) : <Uint8Array>value

            const decodedLength = (value: string | Uint8Array) => {
              return typeof value === 'string' ? value.length : value.byteLength
            }

            // In IE, Edge, Safari, Chrome and other chromium based browsers
            // everything comes as one piece.
            // The server requires chunks so we split them here.
            if ((options || {}).upload) {
              while (decodedLength(decodedValue) > 512000) {
                const chunk = decodedValue.slice(0, 512000)
                decodedValue = decodedValue.slice(512000)
                controller.enqueue(chunk)
              }
            }

            if (decodedLength(decodedValue) > 0) {
              controller.enqueue(decodedValue)
            }
          }

          if (done && !closed) {
            controller.close()
            closed = true
            return
          }
          return push()
        }

        push()
      },
    },
    new CountQueuingStrategy({ highWaterMark: 1 }),
  )

  return stream
}

function readFromFileReader(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (evt: ProgressEvent<FileReader>) {
      if (evt.target) {
        resolve(evt.target.result)
      } else {
        reject(new Error('Error trying to read from FileReader'))
      }
    }

    reader.readAsArrayBuffer(file)
  })
}

export default async function getReadableStream(file: File, decode = false) {
  let reader
  const body = new Response(file).body

  if (body) {
    reader = body.getReader()
  } else {
    // Needed for IE11
    const fileContent = await readFromFileReader(file)
    reader = {
      cancel: () => Promise.resolve(),
      releaseLock: () => {},
      read: async () => {
        return {
          done: true,
          value: fileContent,
        }
      },
    } as any
  }

  return readerToStream(reader, { upload: true, decode })
}
